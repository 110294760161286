<div
  class="flex items-center justify-between bg-[#101820] py-4 px-4 sticky top-0 z-50"
>
  <div class="flex items-center">
    <!-- Hamburger Button -->
    <button
      (click)="toggleMenu()"
      class="text-white focus:outline-none"
      data-menu-toggle
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
  </div>

  <div class="flex gap-2 items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="green"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#1f2937"
      class="size-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
      />
    </svg>
    <h4 class="text-white capitalize">Olá, {{ userName }} &nbsp;|</h4>
    <p class="text-white font-semibold uppercase">{{ userProfile }}</p>
  </div>

  <!-- DarkMode toggle area -->
  <div class="flex items-center justify-end transition-colors">
    <label for="darkModeToggle" class="flex items-center cursor-pointer">
      <div class="relative">
        <input
          id="darkModeToggle"
          type="checkbox"
          class="sr-only"
          (change)="toggleDarkMode()"
          [checked]="isDarkMode()"
        />
        <div
          class="block w-10 h-6 bg-gray-400 dark:bg-[#8E55D2] rounded-full"
        ></div>
        <div
          class="dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition transform"
          [class.translate-x-4]="isDarkMode()"
          [class.translate-x-0]="!isDarkMode()"
        ></div>
      </div>
      <div class="ml-3 text-white font-medium text-sm">Modo Escuro</div>
    </label>
  </div>
</div>

<!-- Menu Lateral -->
<div
  class="fixed w-72 h-full z-[99] bg-white dark:bg-[#323639] transition-transform duration-300 ease-in-out transform menu border-r flex flex-col justify-between"
  [class.translate-x-0]="isMenuOpen"
  [class.-translate-x-full]="!isMenuOpen"
>
  <!-- Conteúdo do Menu -->
  <div>
    <div class="text-sm px-4 py-4">
      <h2 class="text-purple-500 dark:text-gray-100 pb-2 font-semibold">
        {{ unitName }}
      </h2>
      <h3 class="text-black dark:text-gray-200 text-xs">
        {{ currentMachine }}
      </h3>
    </div>
    <hr class="border-b border-gray-300 mx-4" />
    <nav
      class="my-4 px-2 text-black dark:text-gray-100 text-sm flex flex-col gap-2"
    >
      <a
        href="/home"
        class="flex items-center gap-4 py-2 px-4 rounded hover:bg-purple-100 dark:hover:bg-gray-600"
      >
        <!-- Icone de Início -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          class="size-5 stroke-current text-[#8B45DC] dark:text-gray-200"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
          />
        </svg>
        <p>Início</p>
      </a>
      <a
        *ngIf="isAdmin"
        href="/computer-settings"
        class="flex items-center gap-4 py-2 px-4 rounded hover:bg-purple-100 dark:hover:bg-gray-600"
      >
        <!-- Icone de Gerenciar Guichês -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          class="size-5 stroke-current text-[#8B45DC] dark:text-gray-200"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
          />
        </svg>
        <p>Gerenciar Guichês</p>
      </a>
      <a
        *ngIf="isAdmin"
        href="/queue-settings"
        class="flex items-center gap-4 py-2 px-4 rounded hover:bg-purple-100 dark:hover:bg-gray-600"
      >
        <!-- Icone de Gerenciar Chamada Inteligente -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          class="size-6 stroke-current text-[#8B45DC] dark:text-gray-200"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
          />
        </svg>
        <p>Gerenciar Chamada Inteligente</p>
      </a>
    </nav>
  </div>

  <!-- Botão de Logout -->
  <div class="px-2 mb-20">
    <button
      (click)="logout()"
      class="flex gap-2 text-black dark:text-white font-medium p-2 w-full rounded-lg hover:bg-purple-100 dark:hover:bg-gray-600"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#8B45DC"
        class="size-5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
        />
      </svg>
      Sair
    </button>
  </div>
</div>
