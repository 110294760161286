<app-header></app-header>

<body class="bg-gray-200 dark:bg-[#24292D] min-h-screen min-w-full py-4 px-32">
  <div class="w-full mx-auto rounded-lg">
    <div class="flex justify-between items-center p-4">
      <h2 class="text-lg font-semibold dark:text-gray-200">
        GERENCIAR GUICHÊS
      </h2>
      <button
        (click)="backToDashboard()"
        class="text-[#8B45DC] font-medium flex items-center gap-2 px-3 border rounded-lg border-[#8B45DC] hover:bg-[#8B45DC] hover:text-white dark:text-white dark:border-[#8B45DC] dark:bg-[#8B45DC] dark:hover:bg-[#7541b1]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
          />
        </svg>
        Voltar
      </button>
    </div>

    <!-- Table -->
    <div
      class="px-4 bg-white dark:bg-[#384048] rounded-md max-h-[700px] overflow-y-scroll"
    >
      <table class="min-w-full">
        <thead>
          <tr>
            <th
              class="py-4 text-left text-gray-600 dark:text-gray-100 w-[350px]"
            >
              Guichê
            </th>
            <th
              class="py-4 text-left text-gray-600 dark:text-gray-100 w-[350px]"
            >
              Tipo de Atendimento
            </th>
            <th
              class="py-4 text-left text-gray-600 dark:text-gray-100 w-[350px]"
            >
              Chamada Manual
            </th>
            <th class="py-4 text-left text-gray-600 dark:text-gray-100">
              Editar
            </th>
          </tr>
        </thead>
        <tbody *ngFor="let computer of computers">
          <tr class="border-t">
            <td class="py-4 dark:text-gray-100">{{ computer.name }}</td>
            <td>
              <select
                (change)="onChangeTypeOfService($event, computer)"
                [disabled]="!computer.isEditing"
                [value]="computer.registeredQueue"
                class="w-64 font-medium rounded-md border p-1 text-sm dark:bg-[#384048] dark:text-gray-50 focus-visible:border-purple-500 focus:outline-purple-500 dark:focus:outline-[#8B45DC]"
                [ngClass]="{
                  'border-purple-500 dark:border-white': computer.isEditing,
                  'text-gray-500': !computer.isEditing
                }"
              >
                <option value="1">Misto</option>
                <option value="2">Particular</option>
                <option value="3">Plano</option>
              </select>
            </td>
            <td>
              <select
                (change)="onChangeManualCall($event, computer)"
                [disabled]="!computer.isEditing"
                [value]="computer.manualCall"
                class="w-64 font-medium rounded-md border p-1 text-sm dark:bg-[#384048] dark:text-gray-50 focus-visible:border-purple-500 focus:outline-purple-500 dark:focus:outline-[#8B45DC]"
                [ngClass]="{
                  'border-purple-500 dark:border-white': computer.isEditing,
                  'text-gray-500': !computer.isEditing
                }"
              >
                <option value="false">Inativa</option>
                <option value="true">Ativa</option>
              </select>
            </td>
            <td class="text-start">
              <div *ngIf="!computer.isEditing">
                <button
                  (click)="editTicketWindown(computer)"
                  class="p-2 bg-[#CFCEDF] rounded-lg"
                >
                  <!-- Ícone de editar -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#384048"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                    />
                  </svg>
                </button>
              </div>
              <div *ngIf="computer.isEditing" class="flex gap-2">
                <button
                  (click)="setConfiguration(computer)"
                  class="p-2 bg-[#00AC70] stroke-white rounded-lg"
                >
                  <!-- Ícone de salvar -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </button>
                <button
                  (click)="editTicketWindown(computer)"
                  class="p-2 bg-[#B4003C] stroke-white rounded-lg"
                >
                  <!-- Ícone de cancelar -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</body>

<!-- Info Modal  -->
<app-modal *ngIf="isModalOpen" (onClick)="closeModal()" [title]="modalTitle">
  <div selector="body" class="mt-4 px-6">
    {{ modalMessage }}
  </div>
  <div selector="footer" class="flex justify-end mt-4 px-4 py-4">
    <button
      (click)="closeModal()"
      class="border border-[#00AC70] bg-[#00AC70] text-white hover:text-white dark:bg-[#00AC70] dark:border-[#00AC70] dark:text-white dark:hover:bg-[#00AC70] px-4 py-2 rounded"
    >
      Fechar
    </button>
  </div>
</app-modal>
