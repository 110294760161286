import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CustomButtonComponent } from '../custom-button/custom-button.component';
import { Router } from '@angular/router'
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { DarkModeService } from '../../../services/dark-mode/dark-mode.service';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CustomButtonComponent, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(private router: Router, private localStorageService: LocalStorageService, private darkModeService: DarkModeService, private userService: UserService) {
    this.userName = (this.localStorageService.get('login').toLowerCase());
    this.unitName = this.localStorageService.get('unitName');
    this.currentMachine = this.userService.getTicketWindow();
    this.userProfile = this.localStorageService.get('profile');
  }

  @Output() menuState = new EventEmitter<boolean>();

  userName!: string;
  unitName!: string;
  userProfile!: string;
  currentMachine!: string;

  isAdmin = false;

  isMenuOpen = false;

  ngOnInit() {
    this.isAdmin = this.userProfile !== 'Operador';
  }

  toggleDarkMode(): void {
    this.darkModeService.toggleDarkMode();
  }

  isDarkMode(): boolean {
    return this.darkModeService.isDarkMode();
  }

  logout() {
    this.localStorageService.clear();
    this.router.navigate(['/login']);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuState.emit(this.isMenuOpen);
  }

  // Detectar clique fora do menu
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    // Verifica se o clique foi fora do menu e do botão hamburguer
    if (this.isMenuOpen && !targetElement.closest('.menu') && !targetElement.closest('[data-menu-toggle]')) {
      this.isMenuOpen = false;
    }
  }
}
