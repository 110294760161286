<app-header></app-header>

<div
  *ngIf="isLoading"
  class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50"
>
  <!-- Spinner de carregamento -->
  <div
    class="border-gray-300 h-12 w-12 animate-spin rounded-full border-4 border-t-green-500"
  ></div>
</div>

<body
  class="bg-[#CFCEDF] dark:bg-[#323639] min-h-screen min-w-full p-4"
  (click)="closeMenu()"
>
  <div class="gap-2 grid overflow-y-hidden">
    <div *ngIf="!isAdmin" class="mt-2">
      <div
        *ngIf="!selectedCallPassword; else passwordSelectedArea"
        class="flex gap-6 justify-between pb-6 px-6"
      >
        <button
          (click)="gerenateExpressPassword()"
          [disabled]="disabledButton()"
          title="Gera uma senha express"
          [ngClass]="{
            'border-purple-600 text-purple-600 hover:text-white hover:bg-purple-600 dark:border-[#9C5FE5] dark:text-[#9C5FE5] dark:hover:bg-[#9C5FE5] dark:hover:text-white':
              !disabledButton(),
            'border-gray-400 text-gray-400 cursor-not-allowed': disabledButton()
          }"
          class="px-4 py-2 rounded-lg border-2 font-semibold"
        >
          Senha Express
        </button>
        <button
          (click)="callNextPassword()"
          [disabled]="disabledSmartQueueButton()"
          [title]="infoTitleTooltip()"
          class="px-4 py-2 rounded-lg flex items-center justify-center gap-2 text-white"
          [ngClass]="{
            'bg-gray-400 cursor-not-allowed hover:bg-gray-400 dark:bg-gray-400':
              disabledSmartQueueButton(),
            'bg-[#00AC70] hover:bg-green-600 hover:cursor-pointer dark:bg-[#29936E]':
              !disabledSmartQueueButton()
          }"
        >
          Fila Inteligente
          <svg
            selector="icon"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            />
          </svg>
        </button>
      </div>

      <ng-template #passwordSelectedArea>
        <div
          *ngIf="selectedCallPassword"
          class="grid grid-cols-3 px-4 py-2 bg-white dark:bg-[#24292D] shadow-xl"
        >
          <div class="col-span-2 flex flex-col gap-y-2">
            <div class="flex">
              <h2 class="font-semibold text-xl dark:text-white">
                Senha {{ selectedCallPassword.dsPassword }}
              </h2>
              <div
                *ngIf="
                  selectedCallPassword.overflowed &&
                  selectedCallPassword.planDescription != queueName[filter] &&
                  queueName[filter] != 'Misto'
                "
                class="ml-5 mt-0.5"
              >
                <small
                  class="font-semibold bg-[#FFCDAE] text-[#5C2B0D] text-xs font-medium me-2 px-2.5 py-0.5 rounded"
                >
                  Atendimento de apoio
                </small>
              </div>
            </div>
            <p *ngIf="isExpress" class="text-red-500">Senha Express</p>
            <hr *ngIf="!isExpress" class="border-b" />
            <div
              *ngIf="!isExpress"
              class="grid grid-cols-6 font-medium text-sm text-[#24292D] dark:text-white"
            >
              <p class="col-span-2" [title]="selectedCallPassword.name">
                {{ selectedCallPassword.name | titlecase }}
              </p>
              <div class="flex gap-1">
                <p
                  *ngIf="selectedCallPassword.cpf"
                  [title]="selectedCallPassword.cpf"
                >
                  CPF {{ formatCpf(selectedCallPassword.cpf) }}
                </p>
                <p
                  *ngIf="!selectedCallPassword.cpf"
                  [title]="selectedCallPassword.cpf"
                >
                  Paciente sem CPF
                </p>
                <img
                  *ngIf="selectedCallPassword.cpf"
                  [src]="
                    !isDarkMode()
                      ? 'copy-to-clipboard-black-icon.png'
                      : 'copy-to-clipboard-white-icon.png'
                  "
                  alt="Copiar"
                  class="copy-to-clipboard dark:white"
                  title="Copiar"
                  (click)="copyToClipboard(selectedCallPassword.cpf)"
                />
              </div>
              <p *ngIf="selectedCallPassword.age" class="ml-6">
                {{ selectedCallPassword.age }} anos
              </p>
              <p [title]="selectedCallPassword.planDescription">
                {{ selectedCallPassword.planDescription }}
              </p>
              <p [title]="selectedCallPassword.specialty">
                {{ selectedCallPassword.specialty }}
              </p>
            </div>
          </div>
          <div class="flex gap-4 justify-center items-center">
            <div class="flex h-8 gap-4" style="margin-left: 5rem">
              <button
                [disabled]="isdisabled()"
                (click)="callPassword(selectedCallPassword)"
                class="px-4 rounded-lg flex items-center justify-center gap-2"
                [ngClass]="{
                  'border border-[#8B45DC] text-[#8B45DC] font-semibold hover:bg-[#8B45DC] hover:text-white':
                    !isdisabled(),
                  'btn-disabled cursor-not-allowed': isdisabled()
                }"
              >
                <span *ngIf="!showSpinner">Chamar</span>
                <svg
                  *ngIf="!showSpinner"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                  />
                </svg>
                <span *ngIf="showSpinner">
                  <svg
                    aria-hidden="true"
                    class="inline size-5 text-gray-200 animate-spin dark:text-gray-600 fill-[#00CD78]"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </span>
              </button>
              <button
                (click)="finishPassword(selectedCallPassword)"
                [disabled]="!selectedCallPassword"
                class="px-4 rounded-lg flex items-center justify-center gap-2"
                [ngClass]="{
                  'border border-[#00CD78] text-[#00CD78] hover:bg-[#00CD78] hover:text-white font-semibold':
                    selectedCallPassword,
                  'btn-disabled cursor-not-allowed': !selectedCallPassword
                }"
              >
                Atendimento
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <hr
      *ngIf="!isAdmin && !selectedCallPassword"
      class="border-b border-gray-400"
    />

    <div class="flex gap-6 mt-4 dark:text-white">
      <p *ngIf="isAdmin" class="font-semibold pl-1">ACOMPANHAMENTO GERAL</p>
      <!-- <p *ngIf="!isAdmin" class="text-sm pl-4">
        Tipo de Atendimento: &nbsp;<strong>{{ queueName[filter] }}</strong>
      </p> -->
    </div>
    <!-- Fila Prioridade -->
    <div class="bg-white dark:bg-[#24292D] p-2 shadow-md flex-1">
      <div class="">
        <div class="flex items-center gap-1 px-2 pb-2 dark:text-gray-100">
          <h4 class="font-semibold text-sm">Fila Prioridade</h4>
          <p>({{ countPasswordPriority() }})</p>
        </div>
        <div class="overflow-y-auto max-h-72">
          <table class="bg-white w-full cursor-pointer">
            <thead
              class="bg-[#384048] text-white text-xs leading-normal sticky top-0"
            >
              <tr>
                <th class="py-2 px-1 text-center w-[84px]">Senha</th>
                <th class="py-2 px-1 text-left w-[400px]">Nome</th>
                <th class="py-2 px-1 text-center w-[84px]">Idade</th>
                <th class="py-2 px-1 text-center w-[400px]">Plano</th>
                <th class="py-2 px-1 text-center w-[190px]">Agendamento</th>
                <th class="py-2 px-1 text-center w-[275px]">
                  Tipo de Atendimento
                </th>
                <th class="py-2 px-1 text-start w-[186px]">Espera</th>
                <th class="py-2 px-1 text-center w-[210px]">Guichê</th>
              </tr>
            </thead>
            <tbody
              *ngFor="let password of passwordsFiltered"
              class="text-black dark:text-white dark:bg-[#24292D] text-xs font-normal"
            >
              <tr
                *ngIf="password.priority"
                class="border-b dark:border-b-[#384048]"
                [ngClass]="{
                  'bg-gray-200 dark:bg-black': isRowSelected(password),
                  'hover:bg-gray-100 dark:hover:bg-zinc-900 dark:hover:text-white':
                    !isRowSelected(password),
                  'cursor-pointer': true
                }"
                (click)="onRowClick($event, password)"
              >
                <td class="py-1 px-1 text-center">{{ password.dsPassword }}</td>
                <td class="py-1 px-1 text-left">{{ password.name }}</td>
                <td class="py-1 px-1 text-center">{{ password.age }}</td>
                <td class="py-1 px-1 text-center">
                  {{ password.planDescription }}
                </td>
                <td
                  class="py-1 px-1 text-center flex items-center justify-center gap-2"
                >
                  {{
                    bringScheduleTime(
                      password.hasSchedule,
                      password.scheduledTo
                    )
                  }}
                </td>
                <td class="py-1 px-1 text-center">{{ password.specialty }}</td>
                <td class="py-1 px-1 flex items-center gap-2 min-w-24 mx-auto">
                  <div
                    class="rounded-full w-2 h-2"
                    [ngClass]="{
                      'bg-[#00CD78] dark:bg-[#00CD78]':
                        delayColor[password.id] === 'green',
                      'bg-[#FF7F30] dark:bg-[#FF7F30]':
                        delayColor[password.id] === 'yellow',
                      'bg-[#B4003C] dark:bg-[#F00050]':
                        delayColor[password.id] === 'red'
                    }"
                  ></div>
                  <div>
                    {{ elapsedTimes[password.id] }}
                  </div>
                </td>
                <td class="py-1 px-1 text-center">
                  {{ password.calledMachine }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Fila Padrão -->
    <div class="bg-white dark:bg-[#24292D] p-2 shadow-md flex-1">
      <div class="">
        <div class="flex items-center gap-1 px-2 pb-2 dark:text-gray-100">
          <h4 class="font-semibold text-sm">Fila Padrão</h4>
          <p>({{ countPassword() }})</p>
        </div>
        <div class="overflow-y-auto max-h-72">
          <table class="bg-white w-full cursor-pointer">
            <thead
              class="bg-[#384048] text-white text-xs leading-normal sticky top-0"
            >
              <tr>
                <th class="py-2 px-1 text-center w-[84px]">Senha</th>
                <th class="py-2 px-1 text-left w-[400px]">Nome</th>
                <th class="py-2 px-1 text-center w-[84px]">Idade</th>
                <th class="py-2 px-1 text-center w-[400px]">Plano</th>
                <th class="py-2 px-1 text-center w-[190px]">Agendamento</th>
                <th class="py-2 px-1 text-center w-[275px]">
                  Tipo de Atendimento
                </th>
                <th class="py-2 px-1 text-start w-[186px]">Espera</th>
                <th class="py-2 px-1 text-center w-[210px]">Guichê</th>
              </tr>
            </thead>
            <tbody
              *ngFor="let password of passwordsFiltered"
              class="text-black dark:text-white dark:bg-[#24292D] text-xs font-normal"
            >
              <tr
                *ngIf="!password.priority && password.specialty != 'Express'"
                class="border-b dark:border-b-[#384048]"
                [ngClass]="{
                  'bg-gray-200 dark:bg-black': isRowSelected(password),
                  'hover:bg-gray-100 dark:hover:bg-zinc-900 dark:hover:text-white':
                    !isRowSelected(password),
                  'cursor-pointer': true
                }"
                (click)="onRowClick($event, password)"
              >
                <td class="py-1 px-1 text-center">{{ password.dsPassword }}</td>
                <td class="py-1 px-1 text-left">{{ password.name }}</td>
                <td class="py-1 px-1 text-center">{{ password.age }}</td>
                <td class="py-1 px-1 text-center">
                  {{ password.planDescription }}
                </td>
                <td
                  class="py-1 px-1 text-center flex items-center justify-center gap-2"
                >
                  {{
                    bringScheduleTime(
                      password.hasSchedule,
                      password.scheduledTo
                    )
                  }}
                </td>
                <td class="py-1 px-1 text-center">{{ password.specialty }}</td>
                <td class="py-1 px-1 flex items-center gap-2 min-w-24 mx-auto">
                  <div
                    class="rounded-full w-2 h-2"
                    [ngClass]="{
                      'bg-[#00CD78] dark:bg-[#00CD78]':
                        delayColor[password.id] === 'green',
                      'bg-[#FF7F30] dark:bg-[#FF7F30]':
                        delayColor[password.id] === 'yellow',
                      'bg-[#B4003C] dark:bg-[#F00050]':
                        delayColor[password.id] === 'red'
                    }"
                  ></div>
                  <div>
                    {{ elapsedTimes[password.id] }}
                  </div>
                </td>
                <td class="py-1 px-1 text-center">
                  {{ password.calledMachine }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</body>

<!-- Menu -->
<div
  *ngIf="manualPasswordSelected && showMenu && !isAdmin"
  [ngStyle]="{ top: menuPosition.top, left: menuPosition.left }"
  class="fixed bg-white border border-gray-300 dark:bg-zinc-600 dark:text-white shadow-lg rounded-lg p-2 z-50 w-40"
>
  <div class="mb-1">
    <p class="text-xs">Opções para senha:</p>
    <p class="font-semibold text-xs">{{ manualPasswordSelected.dsPassword }}</p>
  </div>
  <div *ngIf="!cantCall(manualPasswordSelected)" class="grid gap-y-1">
    <button
      *ngIf="manualPasswordSelected.calls < 3 && manualCall"
      (click)="selectPassword(manualPasswordSelected)"
      title="botão usado para chamada manual"
      class="block text-xs text-purple-800 dark:text-white dark:hover:bg-zinc-900 font-semibold w-full text-left px-2 py-1 border-b-purple-800 hover:bg-purple-800 hover:text-white hover:rounded-md"
    >
      Chamar
    </button>
    <hr class="border-b border-gray-200" />
    <button
      (click)="discardPassword()"
      title="botão usado para finalizar um senha"
      class="block text-xs text-purple-800 dark:text-white dark:hover:bg-zinc-900 font-semibold w-full text-left px-2 py-1 border-b-purple-800 hover:bg-purple-800 hover:text-white hover:rounded-md"
    >
      Descartar senha
    </button>
  </div>
  <div *ngIf="cantCall(manualPasswordSelected)" class="grid gap-y-1">
    <p class="text-xs text-white px-2 py-1 bg-slate-400 rounded-md">
      Senha chamada por outro guichê.
    </p>
  </div>
</div>

<!-- TOAST -->
<div
  *ngIf="selectedCallPassword && openToast"
  id="toast-default"
  class="fixed bottom-5 right-5 flex items-center w-full max-w-lg p-4 text-white bg-white rounded-lg shadow"
  role="alert"
>
  <div
    class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-red-500 dark:text-white dark:bg-zinc-600 rounded-lg"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
      />
    </svg>

    <span class="sr-only">Fire icon</span>
  </div>
  <div class="ms-3 text-sm font-normal flex gap-1">
    <p class="text-black">A senha:</p>
    <p class="text-black font-semibold">
      {{ selectedCallPassword.dsPassword }}
    </p>
    <p *ngIf="!isExpress" class="text-black">{{ toasterText }}</p>
    <p *ngIf="isExpress" class="text-black">está sendo chamada.</p>
  </div>
  <button
    type="button"
    (click)="closeToast()"
    class="ms-auto -mx-1.5 -my-1.5 bg-white text-black rounded-lg p-1.5 hover:bg-gray- inline-flex items-center justify-center h-8 w-8"
    data-dismiss-target="#toast-default"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <svg
      class="w-3 h-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  </button>
</div>

<!-- Finish Modal  -->
<app-modal
  *ngIf="isFinishModalOpen"
  (onClick)="closeModal('finish')"
  [title]="'Atendimento senha'"
>
  <form
    [formGroup]="endServiceForm"
    (ngSubmit)="submitPassword()"
    class="px-16"
  >
    <div selector="body" class="mt-4">
      <label
        for="finishPassword"
        class="block text-xs font-medium text-gray-700"
        >Selecione uma ação</label
      >
      <select
        (change)="selectFinishOption($event)"
        name="finishPassword"
        id="finishPassword"
        class="mt-1 block w-full py-1 px-3 border border-gray-300 bg-white dark:bg-zinc-600 dark:text-white dark:focus:ring-0 dark:focus:border-white rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-600 sm:text-sm"
      >
        <option disabled selected>Selecione uma ação</option>
        <option value="1">Paciente atendido</option>
        <option value="2">Paciente não atendido</option>
        <option value="3">Descartar senha</option>
        <option value="4">Vincular paciente a agenda</option>
      </select>
    </div>

    <div class="flex mt-6 mb-10 gap-6">
      <button
        (click)="closeModal('finish')"
        type="button"
        class="border border-purple-600 text-purple-600 px-4 py-1 rounded-lg w-full hover:bg-purple-600 hover:text-white dark:text-white dark:border-white dark:hover:bg-zinc-600 dark:hover:text-white"
      >
        Cancelar
      </button>
      <button
        [disabled]="!isFinishOptionSelected"
        type="submit"
        class="text-white px-4 py-1 rounded-lg w-full"
        [ngClass]="{
          'bg-purple-600 hover:bg-purple-500 dark:bg-purple-800 dark:hover:bg-purple-700':
            isFinishOptionSelected,
          'bg-zinc-300': !isFinishOptionSelected
        }"
      >
        Confirmar
      </button>
    </div>
  </form>
</app-modal>

<!-- Info Modal  -->
<app-modal
  *ngIf="isModalOpen"
  (onClick)="closeModal('info')"
  [title]="modalTitle"
>
  <div selector="body" class="mt-4 px-6">
    {{ modalMessage }}
  </div>
  <div selector="footer" class="flex justify-end mt-4 px-4 py-4">
    <button
      (click)="closeModal('info')"
      class="border border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white dark:bg-purple-800 dark:text-white dark:hover:bg-purple-700 px-4 py-2 rounded"
    >
      Fechar
    </button>
  </div>
</app-modal>
