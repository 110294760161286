import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { catchError, firstValueFrom, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  protected apiVersion = environment.apiVersion;
  protected apiURL = environment.apiUrl;
  

  constructor(protected httpClient: HttpClient, private localStorageService: LocalStorageService) {
    this.apiURL = this.apiURL + '/v' + this.apiVersion;
  }

  public async getPatientByCPF(document: string): Promise<any> {
    return await firstValueFrom(
      this.httpClient.get<any>(`${this.apiURL}/Patient/by-document/${document}`).pipe(
        map(response => response.data[0]),
        catchError(this.handleError)
      )
    );
  }

  public async getPatientByName(name: string, pageNumber: number, pageSize: number): Promise<any> {
    return await firstValueFrom(
      this.httpClient.get<any>(`${this.apiURL}/Patient/by-name/${name}?name=&pageNumber=${pageNumber}&pageSize=${pageSize}`,).pipe(
        map(response => response.data[0]),
        catchError(this.handleError)
      )
    );
  }

  public async getSchedulesByPatient(patientId: number, clinicId: number): Promise<any> {
    return await firstValueFrom(
      this.httpClient.get<any>(`${this.apiURL}/Patient/schedules-by-patient/${patientId}/clinic/${clinicId}`).pipe(
        map(response => response.data[0]),
        catchError(this.handleError)
      )
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Erro do lado do servidor
      switch (error.status) {
        case 400:
          errorMessage = `Bad Request: Verifique os parâmetros enviados.`;
          break;
        case 401:
          errorMessage = `Unauthorized: Requisição não autorizada.`;
          break;
        case 404:
          errorMessage = `Not Found: O recurso solicitado não foi encontrado.`;
          break;
        case 500:
          errorMessage = `Internal Server Error: Houve um problema no servidor. Tente novamente mais tarde.`;
          break;
        default:
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          break;
      }
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }

}
