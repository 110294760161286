import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../../../shared/header/header.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QueueSettings } from '../../../../interfaces/queue-settings';
import { LocalStorageService } from '../../../../services/local-storage/local-storage.service';
import { UserService } from '../../../../services/user/user.service';
import { ModalComponent } from '../../../shared/modal/modal.component';

@Component({
  selector: 'app-queue-settings',
  standalone: true,
  imports: [HeaderComponent, CommonModule, ModalComponent],
  templateUrl: './queue-settings.component.html',
  styleUrl: './queue-settings.component.scss'
})
export class QueueSettingsComponent {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {
    this.unit = this.localStorageService.get("currentUnit");
  }

  values: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  queueSettings!: QueueSettings;

  unit!: number;
  selectedPriority!: number;
  selectedNormal!: number;

  isModalOpen = false;
  modalTitle!: string;
  modalMessage!: string;
  isEditing = false;

  async ngOnInit() {
    const response = await this.userService.getClinicProportion(this.unit);
    response.forEach((setting: any) => {
      if (setting.passwordType === 'NORMAL') {
        this.selectedNormal = setting.quantity;
      } else if (setting.passwordType === 'PRIORIDADE') {
        this.selectedPriority = setting.quantity;
      }
    });
    this.queueSettings = { clinicId: this.unit, qtPriority: this.selectedPriority, qtNormal: this.selectedNormal };
  }

  onChangePriority(event: Event) {
    const numberOfPriority = (event.target as HTMLSelectElement).value;
    this.queueSettings.qtPriority = Number(numberOfPriority);
  }

  onChangeNormal(event: Event) {
    const numberOfNormal = (event.target as HTMLSelectElement).value;
    this.queueSettings.qtNormal = Number(numberOfNormal);
  }

  async setQueueSettings() {
    try {
      await this.userService.setClinicProportion(Number(this.unit), this.queueSettings.qtPriority, this.queueSettings.qtNormal);
      this.isModalOpen = true;
      this.modalTitle = 'Alteração realizada com Sucesso!';
      this.modalMessage = 'A nova proporção de chamada de senhas entre as filas já está ativa.';
    } catch (error) {
      this.isModalOpen = true;
      this.modalTitle = 'Erro';
      this.modalMessage = 'Ocorreu um erro ao salvar as alterações. Tente novamente';
    } finally {
      this.toggleIsEditing();
    }
  }

  toggleIsEditing() {
    this.isEditing = !this.isEditing;
  }

  backToDashboard() {
    this.router.navigate(['/home']);
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
