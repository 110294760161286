import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-form',
  standalone: true,
  imports: [],
  templateUrl: './modal-form.component.html',
  styleUrl: './modal-form.component.scss'
})
export class ModalFormComponent {

  // @Input() btnPrimaryText: string = 'option 1';
  // @Input() btnSecondaryText: string = 'option 2';

  // @Output() onClick = new EventEmitter<void>();
  // @Output() onClick2 = new EventEmitter<void>();

  // buttonClick() {
  //   this.onClick.emit();
  // }

  // buttonClick2() {
  //   this.onClick2.emit();
  // }

}
