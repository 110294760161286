import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { DashboardComponent } from '../../admin/dashboard/dashboard.component';

@Component({
  selector: 'app-custom-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-button.component.html',
  styleUrl: './custom-button.component.scss'
})
export class CustomButtonComponent {

  @Input() text!: string;
  @Input() size!: string;
  @Input() color!: string;
  @Input() showIcon!: boolean;

  @Input() type: string = 'button';


  @Output() onClick = new EventEmitter<void>();

  buttonClick() {
    this.onClick.emit();
  }


}
