import { Component } from '@angular/core';
import { HeaderComponent } from '../../../shared/header/header.component';
import { UserService } from '../../../../services/user/user.service';
import { LocalStorageService } from '../../../../services/local-storage/local-storage.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ComputerDTO } from '../../../../interfaces/computer-dto';
import { ModalComponent } from '../../../shared/modal/modal.component';

@Component({
  selector: 'app-computer-settings',
  standalone: true,
  imports: [HeaderComponent, CommonModule, ModalComponent],
  templateUrl: './computer-settings.component.html',
  styleUrl: './computer-settings.component.scss'
})
export class ComputerSettingsComponent {

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  computers: ComputerDTO[] = [];
  unit!: number;

  isEditing = false;
  computerId!: number;

  selectedServiceType!: string;
  selectedManualCall!: boolean;

  isModalOpen = false;
  modalTitle!: string;
  modalMessage!: string;

  ngOnInit() {
    this.unit = this.localStorageService.get("currentUnit");
    this.loadTicketWindow();
  }

  onChangeTypeOfService(event: Event, computer: ComputerDTO) {
    const serviceType = event.target as HTMLSelectElement;
    computer.registeredQueue = Number(serviceType.value);
  }

  onChangeManualCall(event: Event, computer: ComputerDTO) {
    const manualCall = event.target as HTMLSelectElement;
    computer.manualCall = manualCall.value === 'true';
  }

  editTicketWindown(computer: any) {
    computer.isEditing = !computer.isEditing;
  }

  async setConfiguration(computer: any) {
    try {
      await this.userService.setQueueToComputer(computer.id, Number(this.unit), computer.registeredQueue);
      await this.userService.setManualCall(computer.manualCall, Number(this.unit), computer.id);
      this.isModalOpen = true;
      this.modalTitle = 'Alteração realizada com Sucesso!';
      this.modalMessage = 'Peça ao operador do guichê alterado para sair e entrar novamente no sistema. Assim, as novas permissões serão aplicadas corretamente.';
    } catch (error) {
      this.isModalOpen = true;
      this.modalTitle = 'Erro';
      this.modalMessage = 'Ocorreu um erro ao salvar as alterações. Tente novamente';
    } finally {
      computer.isEditing = !computer.isEditing;
      this.isModalOpen = true;
    }
  }

  async loadTicketWindow() {
    try {
      const response = await (await this.userService.getTicketsWindows(String(this.unit))).toPromise();
      this.computers = response.data;
    } catch (error) {
      console.error('Erro ao carregar guichês:', error);
      // Trate o erro conforme necessário
    }
  }

  backToDashboard() {
    this.router.navigate(['/home']);
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
